// NeuralBackground.js
import React, { useEffect, useRef } from 'react';
import './NeuralBackground.css';

const NeuralBackground = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let animationFrameId;
    let particles = [];

    const setCanvasSize = () => {
      const docHeight = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight
      );
      canvas.width = window.innerWidth;
      canvas.height = docHeight;
    };

    // Create a ResizeObserver to watch for content height changes
    const resizeObserver = new ResizeObserver(() => {
      setCanvasSize();
      initParticles();
    });

    // Observe the document body for size changes
    resizeObserver.observe(document.body);

    setCanvasSize();
    window.addEventListener('resize', () => {
      setCanvasSize();
      initParticles();
    });

    class SandGrain {
      constructor() {
        this.reset();
      }

      reset() {
        this.x = Math.random() * canvas.width;
        this.y = Math.random() * canvas.height;
        this.vx = 1 + Math.random() * 1.5; // Horizontal wind speed
        this.vy = (Math.random() - 0.5) * 0.75; // Slight vertical variation
        this.radius = Math.random() + 0.5; // Sand grain size
        this.alpha = Math.random() * 0.3 + 0.3; // Reduced opacity for subtler effect
        this.waveOffset = Math.random() * Math.PI * 4; // Offset for sine wave oscillation
      }

      update() {
        this.x += this.vx;
        this.y += this.vy;
        this.x += Math.sin(this.waveOffset) * 2.5; // Add sine wave effect
        // Reset position when off-screen
        if (this.x > canvas.width) {
          this.x = -this.radius;
          this.y = Math.random() * canvas.height;
          this.waveOffset = Math.random() * Math.PI * 2; // Reset wave offset
        }
        if (this.y < 0 || this.y > canvas.height) {
          this.y = Math.random() * canvas.height;
        }

        this.waveOffset += 0.1; // Increment wave offset for smooth movement
      }

      draw() {
        ctx.beginPath();
        ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2);
        ctx.fillStyle = `rgba(218, 194, 151, ${this.alpha})`; // Updated to match secondary color with lower opacity
        ctx.fill();
      }
    }

    const initParticles = () => {
      const particleCount = Math.floor((canvas.width * canvas.height) / 800); // Adjusted density for full height
      particles = [];
      for (let i = 0; i < particleCount; i++) {
        particles.push(new SandGrain());
      }
    };

    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      particles.forEach((particle) => {
        particle.update();
        particle.draw();
      });

      animationFrameId = requestAnimationFrame(animate);
    };

    initParticles();
    animate();

    return () => {
      cancelAnimationFrame(animationFrameId);
      window.removeEventListener('resize', setCanvasSize);
      resizeObserver.disconnect();
    };
  }, []);

  return <canvas ref={canvasRef} className="neural-background" />;
};

export default NeuralBackground;
