import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import styles from './FAQSection.module.css';

const FAQSection = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const faqs = [
    {
      question: "How much does it cost to build an MVP?",
      answer: "We provide each of our clients with a custom price based on the project complexity. The MVP includes a functional prototype, UI/UX design, and deployment to production. We offer a 14-day trial period - you pay nothing if you aren't satisfied with the first 2 weeks of our work."
    },
    {
      question: "How fast will an MVP get completed?",
      answer: "Most MVPs are completed within 6-8 weeks. The exact timeline depends on the project scope and complexity. We'll provide a detailed timeline during our initial consultation."
    },
    {
      question: "What does the onboarding process look like?",
      answer: "Our onboarding process begins with a free consultation call to understand your needs. We then create a detailed project plan, establish milestones, and set up communication channels. You'll have direct access to your development team throughout the project."
    },
    {
      question: "How will communication work during a project?",
      answer: "We maintain clear communication through regular status updates, weekly progress meetings, and a dedicated project management platform. You'll have direct access to your development team and can reach out any time through Slack or email."
    },
    {
      question: "What is your project refund policy?",
      answer: "We offer a 14-day trial period for our Product Development package. If you're not satisfied with our work during the first two weeks, you'll receive a full refund. For Custom Development projects, we work with milestone-based payments to ensure your satisfaction at each stage."
    },
    {
      question: "Do you sign non-disclosure agreements?",
      answer: "Yes, we sign NDAs before beginning any project discussions. We take your intellectual property and confidentiality seriously and are happy to use your standard NDA or provide our template."
    }
  ];

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section className={styles.faqSection} id="faq">
      <h2>FAQs</h2>
      <p className={styles.faqSubtitle}>We've done our best to answer the most common questions below.</p>
      
      <div className={styles.faqContainer}>
        {faqs.map((faq, index) => (
          <div 
            key={index}
            className={`${styles.faqItem} ${openIndex === index ? styles.active : ''}`}
            onClick={() => toggleFAQ(index)}
          >
            <div className={styles.faqQuestion}>
              <span>{faq.question}</span>
              {openIndex === index ? <RemoveIcon /> : <AddIcon />}
            </div>
            <div className={`${styles.faqAnswer} ${openIndex === index ? styles.open : ''}`}>
              <p>{faq.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default FAQSection;