import React, { useState, useEffect } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { useLocation, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import './Navbar.css';
import LogoBlank from '../Images/brandmark-design-logo-transparent.png';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const isHomePage = location.pathname === '/';

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    if (!isHomePage) {
      navigate('/');
    } else {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  };

  const menuItems = [
    { title: 'Home', to: 'home' },
    { title: 'Services', to: 'services' },
    { title: 'About', to: 'about' },
    { title: 'FAQ', to: 'faq' }
  ];

  const handleScheduleCall = () => {
    window.open('https://calendly.com/abhishek-koka/30min', '_blank');
  };

  const handleNavigation = (to, isMobile = false) => {
    if (!isHomePage) {
      navigate('/', { state: { scrollTo: to } });
      if (isMobile) setIsOpen(false);
    }
  };

  const renderNavLink = (item, isMobile = false) => {
    if (isHomePage) {
      return (
        <ScrollLink
          key={item.to}
          to={item.to}
          smooth={true}
          offset={-70}
          duration={500}
          className={isMobile ? "mobile-nav-link" : "nav-link"}
          activeClass={isMobile ? "mobile-nav-link-active" : "nav-link-active"}
          onClick={() => {
            if (item.title === 'Home') scrollToTop();
            if (isMobile) setIsOpen(false);
          }}
        >
          {item.title}
        </ScrollLink>
      );
    } else {
      return (
        <button
          key={item.to}
          className={isMobile ? "mobile-nav-link" : "nav-link"}
          onClick={() => handleNavigation(item.to, isMobile)}
        >
          {item.title}
        </button>
      );
    }
  };

  return (
    <nav className={`navbar ${scrolled ? 'navbar-scrolled' : ''}`}>
      <div className="navbar-container">
        {/* Logo */}
        <div
          className="navbar-logo"
          onClick={scrollToTop}
          role="button"
          tabIndex={0}
          onKeyPress={(e) => {
            if (e.key === 'Enter') scrollToTop();
          }}
        >
          <img src={LogoBlank} alt="Logo" className="logo-image" />
          <span className="logo-text">ByteSand</span>
        </div>

        {/* Desktop Menu */}
        <div className="navbar-links">
          {menuItems.map(item => renderNavLink(item))}
          <button className="navbar-cta desktop-cta" onClick={handleScheduleCall}>
            Schedule Call
          </button>
        </div>

        {/* Mobile Menu Button */}
        <button
          className="mobile-menu-button"
          onClick={() => setIsOpen(!isOpen)}
          aria-label="Toggle mobile menu"
        >
          {isOpen ? <CloseIcon /> : <MenuIcon />}
        </button>

        {/* Mobile Menu */}
        <div className={`mobile-menu ${isOpen ? 'mobile-menu-open' : ''}`}>
          {menuItems.map(item => renderNavLink(item, true))}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;