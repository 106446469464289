import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ScrollToTop from './Components/ScrollToTop';
import NeuralBackground from './Components/Background/NeuralBackground';

// Import Components
import Navbar from './Components/Navbar';
import Home from './Components/Home/Home';
import Footer from './Components/Footer';
import PrivacyPolicy from './Components/PrivacyPolicy/PrivacyPolicy';

// The Layout component provides consistent background and structure across all pages
const Layout = ({ children }) => (
  <>
    <NeuralBackground />
    {children}
  </>
);

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <Layout>
        <Routes>
          {/* Home route - displays the navbar and home component */}
          <Route path="/" element={
            <>
              <Navbar />
              <Home />
            </>
          } />

          {/* Privacy Policy route - displays navbar and privacy policy component */}
          <Route path="/privacy-policy" element={
            <>
              <Navbar />
              <PrivacyPolicy />
            </>
          } />
        </Routes>

        {/* Footer appears outside Routes to show on all pages */}
        <Footer />
      </Layout>
    </Router>
  );
};

export default App;