import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from 'react-icons/fa';
import "./Footer.css";

const Footer = () => {
    const location = useLocation();
    const isHomePage = location.pathname === '/';

    const scrollToSection = (sectionId) => {
        const element = document.getElementById(sectionId);
        if (element) {
            const navbarHeight = 70; // Adjust this value based on your navbar height
            const elementPosition = element.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.pageYOffset - navbarHeight;

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        }
    };

    return (
        <div className='footer'>
            <div className='container'>
                {/* Pages Section */}
                <div className='col solutions'>
                    <h1>Pages</h1>
                    <ul>
                        {isHomePage ? (
                            <>
                                <li>
                                    <button 
                                        onClick={() => scrollToSection('home')}
                                        className="link"
                                    >
                                        Home
                                    </button>
                                </li>
                                <li>
                                    <button 
                                        onClick={() => scrollToSection('services')}
                                        className="link"
                                    >
                                        Services
                                    </button>
                                </li>
                                <li>
                                    <button 
                                        onClick={() => scrollToSection('about')}
                                        className="link"
                                    >
                                        About
                                    </button>
                                </li>
                                <li>
                                    <button 
                                        onClick={() => scrollToSection('faq')}
                                        className="link"
                                    >
                                        FAQ
                                    </button>
                                </li>
                            </>
                        ) : (
                            <li>
                                <Link to="/" className="link">
                                    Back to Home
                                </Link>
                            </li>
                        )}
                        <li>
                            <Link to="/privacy-policy" className="link">
                                Privacy Policy
                            </Link>
                        </li>
                    </ul>
                </div>

                {/* Social Media Section */}
                <div className='col social'>
                    <h1>Social</h1>
                    <ul className="social-icons">
                        <li>
                            <a 
                                href="https://facebook.com/" 
                                target="_blank" 
                                rel="noopener noreferrer"
                                aria-label="Facebook"
                            >
                                <FaFacebook size={20} style={{color: '#d3d3d3', marginRight: '10px'}} />
                            </a>
                        </li>
                        <li>
                            <a 
                                href="https://instagram.com/" 
                                target="_blank" 
                                rel="noopener noreferrer"
                                aria-label="Instagram"
                            >
                                <FaInstagram size={20} style={{color: '#d3d3d3', marginRight: '10px'}} />
                            </a>
                        </li>
                        <li>
                            <a 
                                href="https://twitter.com/" 
                                target="_blank" 
                                rel="noopener noreferrer"
                                aria-label="Twitter"
                            >
                                <FaTwitter size={20} style={{color: '#d3d3d3', marginRight: '10px'}} />
                            </a>
                        </li>
                        <li>
                            <a 
                                href="https://linkedin.com/company/bytesandai" 
                                target="_blank" 
                                rel="noopener noreferrer"
                                aria-label="LinkedIn"
                            >
                                <FaLinkedin size={20} style={{color: '#d3d3d3', marginRight: '10px'}} />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            
            {/* Copyright Section */}
            <div className='footer-bottom'>
                <p>&copy; {new Date().getFullYear()} ByteSand LLC. All rights reserved.</p>
            </div>
        </div>
    );
};

export default Footer;