// About.jsx
import React from 'react';
import './About.css';

const About = () => {
  const teamMembers = [
    {
      name: 'Abhishek Koka',
      role: 'Software Engineer',
      image: '/Images/Abhishek.png', // Replace with actual image path
      description: 'Computer Science major and software engineer focused on creating scalable AI implementations.'
    },
    {
      name: 'Vishal Chava',
      role: 'Software Engineer',
      image: '/api/placeholder/300/300', // Replace with actual image path
      description: 'Computer Science major and experienced software engineer dedicated to building custom AI solutions.'
    }
  ];

  return (
    <section id="about" className="about-section">
      <div className="about-container">
        {/* Vision Section */}
        <div className="vision-container">
          <h2 className="section-title">Our Story</h2>
          <p className="vision-text">
            Born at a computer lab in Michigan State University, ByteSand emerged from a powerful vision: 
            to democratize AI for small businesses. We believe that the transformative 
            power of AI shouldn't be limited to just big corporations.
          </p>
          <div className="mission-grid">
            <div className="mission-card">
              <h3>Our Mission</h3>
              <p>
                Building custom AI solutions tailored to each company's unique needs, 
                ensuring small businesses don't get left behind in the AI revolution.
              </p>
            </div>
            <div className="mission-card">
              <h3>Our Vision</h3>
              <p>
                We envision a future where AI-powered solutions are as ubiquitous as 
                internet-enabled devices, empowering businesses of all sizes to thrive.
              </p>
            </div>
          </div>
        </div>

        {/* What Sets Us Apart */}
        <div className="differentiators-container">
          <h2 className="section-title">The ByteSand Difference</h2>
          <div className="differentiators-grid">
            <div className="differentiator-card">
              <h3>Expert Engineers</h3>
              <p>
                Unlike others who rely on no-code tools, we're actual software engineers 
                building custom solutions from the ground up.
              </p>
            </div>
            <div className="differentiator-card">
              <h3>Affordable Solutions</h3>
              <p>
                We deliver enterprise-grade AI solutions at a fraction of the cost, 
                making advanced technology accessible to small businesses.
              </p>
            </div>
            <div className="differentiator-card">
              <h3>Lifetime Support</h3>
              <p>
                We stand behind our work with lifetime product support and a complete 
                satisfaction guarantee.
              </p>
            </div>
            <div className="differentiator-card">
              <h3>Customer First</h3>
              <p>
                We work closely with each client, providing free value assessments 
                and money-back guarantees.
              </p>
            </div>
          </div>
        </div>

        {/* Team Section */}
        {/*<div className="team-container">
          <h2 className="section-title">Meet Our Team</h2>
          <div className="team-grid">
            {teamMembers.map((member, index) => (
              <div key={index} className="team-card">
                <div className="team-image-container">
                  <img src={member.image} alt={member.name} className="team-image" />
                </div>
                <h3>{member.name}</h3>
                <p className="team-role">{member.role}</p>
                <p className="team-description">{member.description}</p>
              </div>
            ))}
          </div>
        </div>*/}
      </div>
    </section>
  );
};

export default About;