import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SecurityIcon from '@mui/icons-material/Security';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import './Hero.css';
import Services from '../Services/Services';
import About from '../About/About';
import PricingSection from '../Pricing/PricingSection';
import FAQSection from '../FAQ/FAQSection';

const Home = () => {
  const [email, setEmail] = useState('');
  const location = useLocation();

  // Handle scroll to section when navigating from other pages
  useEffect(() => {
    if (location.state?.scrollTo) {
      setTimeout(() => {
        const element = document.getElementById(location.state.scrollTo);
        if (element) {
          const navbarHeight = 70;
          const elementPosition = element.getBoundingClientRect().top;
          const offsetPosition = elementPosition + window.pageYOffset - navbarHeight;

          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
          });
        }
      }, 100); // Small delay to ensure elements are rendered
    }
  }, [location.state]);
  
  const handleScheduleCall = () => {
    window.open('https://calendly.com/abhishek-koka/30min', '_blank');
  };

  const handleEmailSubmit = (e) => {
    e.preventDefault();
    // Add your email collection logic here
    alert('Thank you for subscribing! We\'ll be in touch soon.');
    setEmail('');
  };

  const whyWorkWithUs = [
    {
      icon: <WorkspacePremiumIcon sx={{ fontSize: 40, color: '#b88e56' }} />,
      title: "Industry Expertise",
      description: "Vast experience implementing AI solutions across various industries"
    },
    {
      icon: <SecurityIcon sx={{ fontSize: 40, color: '#b88e56' }} />,
      title: "Enterprise Security",
      description: "Bank-grade security protocols and data protection measures"
    },
    {
      icon: <AccessTimeIcon sx={{ fontSize: 40, color: '#b88e56' }} />,
      title: "Rapid Implementation",
      description: "Get your AI solution up and running in weeks, not months"
    },
    {
      icon: <ContactsOutlinedIcon sx={{ fontSize: 40, color: '#b88e56' }} />,
      title: "Dedicated Support",
      description: "24/7 access to our expert support team throughout your journey"
    }
  ];

  return (
    <section id="home" className="home-container">
      {/* Hero Section */}
      <section className="hero-section">
        <div className="hero-content">
          <h1>Transform Your Business with AI-Powered Solutions</h1>
          <p>Get expert consultation on implementing AI solutions that drive real business results.</p>
          <button className="cta-button" onClick={handleScheduleCall}>
            Schedule Your Free Strategy Call
          </button>
        </div>
      </section>

      <section className="proof-section">
        <div className="stats-container">
          <div className="stat-box">
            <div className="stat-number">100%</div>
            <p>Client satisfaction rate</p>
          </div>
          <div className="stat-box">
            <div className="stat-number">50+</div>
            <p>Successful implementations</p>
          </div>
          <div className="stat-box">
            <div className="stat-number">8x</div>
            <p>Average ROI for clients</p>
          </div>
        </div>
      </section>

      {/* Services Section */}
      <section id="services">
        <Services />
      </section>

      {/* Why Work With Us */}
      <section className="why-work-section">
        <h2>Why Work With Us</h2>
        <div className="why-work-grid">
          {whyWorkWithUs.map((item, index) => (
            <div key={index} className="why-work-item">
              <div className="why-work-icon">
                {item.icon}
              </div>
              <h3>{item.title}</h3>
              <p>{item.description}</p>
            </div>
          ))}
        </div>
      </section>

      <section id="about">
        <About />
      </section>

      {/* FAQ Section */}
      <section id="faq">
        <FAQSection />
      </section>

      {/* CTA Section */}
      <section className="cta-section">
        <h2>Ready to Transform Your Business?</h2>
        <p>Join our newsletter for exclusive AI insights</p>
        <form onSubmit={handleEmailSubmit} className="cta-form">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            required
          />
          <button type="submit">Subscribe</button>
        </form>
      </section>
    </section>
  );
};

export default Home;