import React from 'react';
import { Brain, Users, LineChart, Code, Layout, Workflow, ArrowRight } from 'lucide-react';
import "./Services.css";

const ServiceCard = ({ icon: Icon, title, description, price }) => (
  <div className="service-card">
    <div className="service-icon">
      <Icon size={32} />
    </div>
    <h3>{title}</h3>
    <p>{description}</p>
    {price && (
      <div className="service-price">
        <span>Starting at</span>
        <h4>{price}</h4>
      </div>
    )}
    {/* <button className="service-cta">
      Learn More <ArrowRight size={16} className="arrow-icon" />
    </button> */}
  </div>
);

const Services = () => {
  const services = [
    {
      icon: Code,
      title: "AI Web Apps",
      description: "Custom web applications powered by advanced AI technology. From concept to deployment, we build scalable solutions.",
    },
    {
      icon: Brain,
      title: "AI Agents",
      description: "Intelligent automation solutions that transform your business processes. Custom-built for your specific needs.",
    },
    {
      icon: Layout,
      title: "UI/UX Design",
      description: "User-centered design that delivers exceptional experiences. Modern, intuitive interfaces for your AI solutions.",
    },
    {
      icon: Users,
      title: "Custom Software",
      description: "Tailored software solutions that solve your unique business challenges. Built with scalability in mind.",
    },
    {
      icon: Workflow,
      title: "Product Strategy",
      description: "Strategic planning and roadmapping for your AI products. Expert guidance from concept to launch.",
    },
    {
      icon: LineChart,
      title: "Project Planning",
      description: "Comprehensive project management and execution. Ensuring your AI initiatives deliver real business value.",
    }
  ];

  return (
    <section className="services-section" id="services">
      <div className="services-container">
        <div className="services-header">
          <h2>Our Services</h2>
          <p>Transformative AI solutions tailored to your business needs</p>
        </div>
        
        <div className="services-grid">
          {services.map((service, index) => (
            <ServiceCard key={index} {...service} />
          ))}
        </div>

        <div className="services-cta">
          <h3>Not sure which service is right for you?</h3>
          <p>Book a free consultation call to discuss your needs</p>
          <button className="consultation-btn" onClick={() => window.open('https://calendly.com/abhishek-koka/30min', '_blank')}>
            Schedule Free Consultation
          </button>
        </div>
      </div>
    </section>
  );
};

export default Services;